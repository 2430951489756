class PhoneRegex {
  constructor() {
    this.phoneRegexId = undefined;
    this.countryId = undefined;
    this.regex = undefined;
    this.regexTypeId = undefined;
  }

  setPhoneRegexId = phoneRegexId => {
    this.phoneRegexId = phoneRegexId;
    return this;
  };

  getPhoneRegexId() {
    return this.phoneRegexId;
  }

  setCountryId = countryId => {
    this.countryId = countryId;
    return this;
  };

  getCountryId() {
    return this.countryId;
  }

  setRegex = regex => {
    this.regex = regex;
    return this;
  };

  getRegex() {
    return this.regex;
  }

  setRegexTypeId = regexTypeId => {
    this.regexTypeId = regexTypeId;
    return this;
  };

  getRegexTypeId() {
    return this.regexTypeId;
  }
}

export default PhoneRegex;
