/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import mixpanel from 'mixpanel-browser';
import { MixpanelProvider } from 'react-mixpanel';
import { Cookie, usePrevious } from '@vezeeta/web-utils';
import { initSetUpMixpanel } from '@vezeeta/enterprise-layout/lib/app/Integrations';
import { getUser } from '@vezeeta/enterprise-store/lib/actions/user';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

const Layout = ({ ...props }) => {
  const { user } = props;
  const language = Cookie.get(Cookie.LANGUAGE);

  useEffect(() => {
    if (Cookie.get(Cookie.AUTH_TOKEN)) {
      props.getUser(language);
    }
  }, []);

  const prevUser = usePrevious(user);
  useEffect(
    () => {
      if (user && prevUser !== user) {
        initSetUpMixpanel(user, language);
      }
    },
    [user],
  );

  return <MixpanelProvider mixpanel={mixpanel}>{props.children}</MixpanelProvider>;
};

Layout.propTypes = {
  user: PropTypes.object,
  getUser: PropTypes.func,
  children: PropTypes.node,
};

const mapStateToProps = state => ({
  user: state.user.user,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getUser,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Layout);
