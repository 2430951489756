import Item from './Item';

class Product {
  constructor() {
    this.productInstance = undefined;
    this.productName = undefined;
    this.productId = undefined;
    this.productUrl = undefined;
    this.remove = false;
    this.items = [];
  }

  addItem = () => {
    const newItem = new Item();
    this.items.push(newItem);
    return newItem;
  };

  getItems() {
    return this.items;
  }

  clearItems() {
    this.items = [];
  }

  setProductInstance = productInstance => {
    this.productInstance = productInstance;
    return this;
  };

  getProductInstance() {
    return this.productInstance;
  }

  setProductName = productName => {
    this.productName = productName;
    return this;
  };

  getProductName() {
    return this.productName;
  }

  setProductId = productId => {
    this.productId = productId;
    return this;
  };

  getProductId() {
    return this.productId;
  }

  setProductUrl = productUrl => {
    this.productUrl = productUrl;
    return this;
  };

  getProductUrl() {
    return this.productUrl;
  }

  setIsToBeRemoved = remove => {
    this.remove = remove;
    return this;
  };

  isToBeRemoved() {
    return this.remove;
  }

  removeProduct = () => {
    this.productInstance.remove();
  };
}

export default Product;
