import React, { useEffect, useState } from 'react';
// import { connect } from 'react-redux';
import { language } from '@vezeeta/enterprise-store/lib/types/language';

// eslint-disable-next-line import/prefer-default-export
export const withTranslation = WrappedComponent => {
  const Localization = props => {
    const [localization, setLocalization] = useState(undefined);
    useEffect(
      () => {
        // Only English is supported for now
        fetch('/localization/en/en-eg.json')
          .then(response => response.json())
          .then(locale => {
            setLocalization(locale);
          })
          .catch(() => {
            fetch(`/localization/${language}/${language}-EG.json`)
              .then(response => response.json())
              .then(locale => {
                setLocalization(locale);
              })
              .catch(() => {});
          });
      },
      [props],
    );

    return <WrappedComponent localization={localization} />;
  };

  return Localization;
};
