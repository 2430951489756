class City {
  constructor() {
    this.cityId = undefined;
    this.nameEnglish = undefined;
    this.nameArabic = undefined;
    this.displayOrderEnglish = undefined;
    this.displayOrderArabic = undefined;
    this.isDeleted = undefined;
    this.countryId = undefined;
    this.isDefault = undefined;
  }

  setCityId = cityId => {
    this.cityId = cityId;
    return this;
  };

  getCityId() {
    return this.cityId;
  }

  setNameEnglish = nameEnglish => {
    this.nameEnglish = nameEnglish;
    return this;
  };

  getNameEnglish() {
    return this.nameEnglish;
  }

  setNameArabic = nameArabic => {
    this.nameArabic = nameArabic;
    return this;
  };

  getNameArabic() {
    return this.nameArabic;
  }

  setDisplayOrderEnglish = displayOrderEnglish => {
    this.displayOrderEnglish = displayOrderEnglish;
    return this;
  };

  getDisplayOrderEnglish() {
    return this.displayOrderEnglish;
  }

  setDisplayOrderArabic = displayOrderArabic => {
    this.displayOrderArabic = displayOrderArabic;
    return this;
  };

  getDisplayOrderArabic() {
    return this.displayOrderArabic;
  }

  setIsDeleted = isDeleted => {
    this.isDeleted = isDeleted;
    return this;
  };

  getIsDeleted() {
    return this.isDeleted;
  }

  setCountryId = countryId => {
    this.countryId = countryId;
    return this;
  };

  getCountryId() {
    return this.countryId;
  }

  setIsDefault = isDefault => {
    this.isDefault = isDefault;
    return this;
  };

  getIsDefault() {
    return this.isDefault;
  }
}

export default City;
