class PaymentCycle {
  constructor() {
    this.paymentCycleId = undefined;
    this.nameEnglish = undefined;
    this.nameArabic = undefined;
    this.countryId = undefined;
    this.isActive = undefined;
    this.isDeleted = undefined;
  }

  setPaymentCycleId = paymentCycleId => {
    this.paymentCycleId = paymentCycleId;
    return this;
  };

  getPaymentCycleId() {
    return this.paymentCycleId;
  }

  setNameEnglish = nameEnglish => {
    this.nameEnglish = nameEnglish;
    return this;
  };

  getNameEnglish() {
    return this.nameEnglish;
  }

  setNameArabic = nameArabic => {
    this.nameArabic = nameArabic;
    return this;
  };

  getNameArabic() {
    return this.nameArabic;
  }

  setCountryId = countryId => {
    this.countryId = countryId;
    return this;
  };

  getCountryId() {
    return this.countryId;
  }

  setIsActive = isActive => {
    this.isActive = isActive;
    return this;
  };

  getIsActive() {
    return this.isActive;
  }

  setIsDeleted = isDeleted => {
    this.isDeleted = isDeleted;
    return this;
  };

  getIsDeleted() {
    return this.isDeleted;
  }
}

export default PaymentCycle;
