import styled from 'styled-components';
import { Subheading } from '@vezeeta/web-components';

export const DoctorName = styled(Subheading)`
  font-weight: 500;
  margin: ${props => (props.language === 'en' ? '0px 12px 0px 0px' : '0px 0px 0px 12px')};
  display: none;
  ${props => props.theme.media.desktop`
    display: block;
  `};
`;

export const HeadersTitles = styled(Subheading)`
  font-size: 14px !important;
`;

export const HeaderContainer = styled.header`
  align-items: center;
  background-color: rgb(0, 112, 205);
  border-bottom: 1px solid #e0e0e0;
  display: flex !important;
  grid-area: a;
  height: 100%;
  justify-content: space-between;
  padding: ${props => (props.language === 'en' ? '0px 16px 0px 0px' : '0px 0px 0px 16px')};
  position: relative;
`;

export const VezeetaLogo = styled.img`
  width: 134px;
  margin: 0px 30px;
  cursor: pointer;
`;
