/* global process */

import { API, encrypt, decrypt, Cookie, Urls } from '@vezeeta/web-utils';

import { User, Headers } from '../_Objects';

let instance = null;

class AccountPaymentMethod {
  constructor() {
    this.user = User.getInstance();

    this.cached = undefined;
    this.paymentTypeKey = undefined;
    this.paymentMethodKey = undefined;
  }

  static getInstance() {
    if (!instance) {
      instance = new AccountPaymentMethod();
    }
    return instance;
  }

  loadAccountPaymentMethodDetails() {
    return new Promise(resolve => {
      this.getIsSet();
      if (this.isLoaded()) {
        const getAccountPaymentDetails = new API();

        getAccountPaymentDetails
          .get(
            `${Urls.payment.getAccountPaymentDetails}${this.user.getAccountKey()}`,
            Headers.getPaymentHeaders(),
          )
          .then(response => {
            this.setPaymentTypeKey(response.data.PaymentTypeKey).setPaymentMethodKey(
              response.data.PaymentMethodKey,
            );
            resolve();
          });
      } else {
        resolve();
      }
    });
  }

  getIsSet() {
    if (this.cached === undefined) {
      if (Cookie.get(Cookie.PAYMENT_TYPE_KEY)) {
        this.setIsLoaded(true);
      }
    }
    return this.cached;
  }

  setIsLoaded = cached => {
    this.cached = cached;
    return this;
  };

  isLoaded() {
    if (this.cached === undefined || this.cached === '') {
      return false;
    }

    return true;
  }

  saveCookie = () => {
    Cookie.set(Cookie.PAYMENT_TYPE_KEY, encrypt(this.paymentTypeKey));
  };

  setPaymentTypeKey = paymentTypeKey => {
    this.paymentTypeKey = paymentTypeKey;
    return this;
  };

  getPaymentTypeKey() {
    if (this.paymentTypeKey === undefined) {
      const savedKey = Cookie.get(Cookie.PAYMENT_TYPE_KEY);
      if (savedKey) {
        this.setPaymentTypeKey(decrypt(savedKey));
      }
    }
    return this.paymentTypeKey;
  }

  setPaymentMethodKey = paymentMethodKey => {
    this.paymentMethodKey = paymentMethodKey;
    return this;
  };

  getPaymentMethodKey() {
    return this.paymentMethodKey;
  }
}
export default AccountPaymentMethod;
