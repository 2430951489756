/* global process */

import { API, decrypt, encrypt, convertImgToBase64, Cookie, Urls } from '@vezeeta/web-utils';

import { Config } from '../_objects/_Objects';

let instance = null;

class User {
  constructor() {
    this.accountKey = undefined;
    this.accountToken = undefined;
    this.photo = undefined;
    this.photoExtension = undefined;
    this.firstName = process.env.REACT_APP_FIRST_NAME;
    this.lastName = process.env.REACT_APP_LAST_NAME;
    this.countryCodeId = Config.getInstance().getCountryCodeId();
    this.mobileNumber = process.env.REACT_APP_MOBILE_NUMBER;
    this.specialityKey = process.env.REACT_APP_SPECIALTY;
    this.email = process.env.REACT_APP_EMAIL;
    this.password = process.env.REACT_APP_PASSWORD;
    this.socialNetworkTypeId = undefined;
    this.isAccountLoaded = false;
    this.countryIsoCode = undefined;
  }

  static getInstance() {
    if (!instance) {
      instance = new User();
    }

    return instance;
  }

  loadUserData = () =>
    new Promise(resolve => {
      this.getAccountKey();
      this.getAccountToken();
      if (this.isAlreadyRegistered() && !this.isAccountLoaded) {
        const getAccountHeader = [
          {
            key: 'x-vzt-authentication',
            value: this.accountToken,
          },
        ];
        const getAccount = new API();

        getAccount.get(Urls.account.getAccount, getAccountHeader).then(response => {
          this.isAccountLoaded = true;

          this.setFirstName(response.data.FirstName)
            .setLastName(response.data.LastName)
            .setCountryCodeId(response.data.CountryCodeId)
            .setMobileNumber(response.data.PhoneNumber)
            .setEmail(response.data.EmailAddress)
            .setCountryIsoCode(response.data.CountryIsoCode)
            .setPhoto(
              response.data.ProfilePhotoPath.toLowerCase().match(/\.(jpg|png|jpeg|svg)/g) !== null
                ? response.data.ProfilePhotoPath
                : undefined,
            );

          if (this.getPhoto()) {
            convertImgToBase64(this.getPhoto(), base64 => {
              this.setPhoto(base64);
              resolve();
            });
          } else {
            resolve();
          }
        });
      } else {
        resolve();
      }
    });

  clearUserData = () => {
    this.accountKey = undefined;
    this.photo = undefined;
    this.photoExtension = undefined;
    this.firstName = undefined;
    this.lastName = undefined;
    this.countryCodeId = Config.getInstance().getCountryCodeId();
    this.mobileNumber = undefined;
    this.email = undefined;
    this.password = undefined;
    this.socialNetworkTypeId = undefined;
  };

  isAlreadyRegistered = () => {
    this.getAccountKey();
    if (this.accountKey === undefined || this.accountKey === '') {
      return false;
    }
    return true;
  };

  isPartialEdit = () => !!(Cookie.get(Cookie.START_ROUTE) && Cookie.get(Cookie.END_ROUTE));

  setAccountKey = accountKey => {
    this.accountKey = accountKey;
    return this;
  };

  getAccountKey() {
    if (this.accountKey === undefined) {
      const savedKey = Cookie.get(Cookie.ACCOUNT_KEY);
      if (savedKey) {
        this.setAccountKey(decrypt(savedKey));
      }
    }
    return this.accountKey;
  }

  setAccountToken = accountToken => {
    this.accountToken = accountToken;
    return this;
  };

  getAccountToken = () => {
    if (this.accountToken === undefined) {
      const savedToken = Cookie.get(Cookie.TEMP_AUTH_TOKEN)
        ? Cookie.get(Cookie.TEMP_AUTH_TOKEN)
        : Cookie.get(Cookie.AUTH_TOKEN);
      if (savedToken) {
        this.setAccountToken(savedToken);
      }
    }
    return this.accountToken;
  };

  saveCookie = () => {
    this.isAccountLoaded = true;
    Cookie.set(Cookie.ACCOUNT_KEY, encrypt(this.accountKey));
    if (!Cookie.get(Cookie.TEMP_AUTH_TOKEN) && !Cookie.get(Cookie.AUTH_TOKEN)) {
      Cookie.set(Cookie.TEMP_AUTH_TOKEN, this.accountToken);
    }
  };

  setPhoto = (photo, photoExtension) => {
    this.photo = photo;
    this.photoExtension = photoExtension;
    return this;
  };

  getPhoto() {
    return this.photo;
  }

  getPhotoExtension() {
    return this.photoExtension;
  }

  setFirstName = firstName => {
    this.firstName = firstName;
    return this;
  };

  getFirstName() {
    return this.firstName;
  }

  setLastName = lastName => {
    this.lastName = lastName;
    return this;
  };

  getLastName() {
    return this.lastName;
  }

  setCountryCodeId = countryCodeId => {
    this.countryCodeId = countryCodeId;
    return this;
  };

  getCountryCodeId() {
    return this.countryCodeId;
  }

  setMobileNumber = mobileNumber => {
    this.mobileNumber = mobileNumber;
    return this;
  };

  getMobileNumber() {
    return this.mobileNumber;
  }

  setSpecialityKey = specialityKey => {
    this.specialityKey = specialityKey;
    return this;
  };

  getSpecialityKey() {
    return this.specialityKey;
  }

  setEmail = email => {
    this.email = email;
    return this;
  };

  getEmail() {
    return this.email;
  }

  setPassword = password => {
    this.password = password;
    return this;
  };

  getPassword() {
    return this.password;
  }

  setSocialNetworkTypeId = socialNetworkTypeId => {
    this.socialNetworkTypeId = socialNetworkTypeId;
    return this;
  };

  getSocialNetworkTypeId() {
    return this.socialNetworkTypeId;
  }

  setCountryIsoCode = countryIsoCode => {
    this.countryIsoCode = countryIsoCode;
    return this;
  };

  getCountryIsoCode() {
    return this.countryIsoCode;
  }

  isLoaded = () => this.isAccountLoaded;
}

export default User;
