import { ProductLines } from '../../_Objects';

const TRANSACTION = 'Transactional';

class Specialty {
  constructor() {
    this.productLines = ProductLines.getInstance();

    this.productLineId = 1;
    this.specialtyGroupId = 0;
    this.priceTypeId = 0;
    this.numberOfRooms = 1;
    this.allowedPaymentPlans = [];
    this.pricePerSpecialty = 0;
    this.transactionFees = 0;
    this.transactionType = false; // false == transaction is %
  }

  setSpecialtyGroupId = specialtyGroupId => {
    this.specialtyGroupId = specialtyGroupId;
    this.setAllowedPaymentPlans();
    this.getPrice();
    return this;
  };

  getSpecialtyGroupId() {
    return this.specialtyGroupId;
  }

  setPriceTypeId = priceTypeId => {
    this.priceTypeId = priceTypeId;
    this.setAllowedPaymentPlans();
    this.getPrice();
    return this;
  };

  getPriceTypeId() {
    return this.priceTypeId;
  }

  setNumberOfRooms = numberOfRooms => {
    this.numberOfRooms = numberOfRooms;
    return this;
  };

  getNumberOfRooms() {
    return this.numberOfRooms;
  }

  setPricePerSpecialty(pricePerSpecialty) {
    this.pricePerSpecialty = pricePerSpecialty;
    return this;
  }

  getPricePerSpecialty() {
    return this.pricePerSpecialty;
  }

  setTransactionFees(transactionFees) {
    this.transactionFees = transactionFees;
    return this;
  }

  getTransactionFees() {
    return this.transactionFees;
  }

  isTransactionFixed() {
    return this.transactionType;
  }

  getPrice = () => {
    if (
      this.specialtyGroupId !== 0 &&
      this.priceTypeId !== 0 &&
      this.specialtyGroupId !== undefined &&
      this.priceTypeId !== undefined
    ) {
      this.productLines
        .getProductLineById(this.productLineId)
        .getProductLineDetailsByPriceTypeIdAndSpecialtyGroupId(
          this.priceTypeId,
          this.specialtyGroupId,
        )
        .getProductLineAttributes()
        .forEach(productLineAttribute => {
          if (productLineAttribute.getFeesModel().getPaymentAttributeType() === TRANSACTION) {
            this.transactionFees = productLineAttribute.getFeesModel().getValue();

            if (
              productLineAttribute.getFeesModel().getIsPercentage() &&
              this.transactionFees !== 0
            ) {
              this.transactionFees = `${this.transactionFees}%`;
              this.transactionType = false;
            } else {
              this.transactionType = true;
            }
          } else {
            this.pricePerSpecialty = productLineAttribute.getFeesModel().getValue();
          }
        });
    }
  };

  setAllowedPaymentPlans() {
    if (this.specialtyGroupId !== 0 && this.specialtyGroupId !== undefined) {
      this.productLines
        .getProductLineById(this.productLineId)
        .getProductLineDetailsBySpecialtyGroupId(this.specialtyGroupId)
        .getProductLineAttributes()
        .forEach(productLineAttribute => {
          this.allowedPaymentPlans = productLineAttribute.getFeesModel().getAllowedPaymentPlans();
        });
    }
    return this;
  }

  getAllowedPaymentPlans() {
    return this.allowedPaymentPlans;
  }
}

export default Specialty;
