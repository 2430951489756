class Item {
  constructor() {
    this.numberOfUnits = undefined;
    this.unit = undefined;
    this.branchName = undefined;
    this.totalPrice = undefined;
    this.paymentCycle = undefined;
    this.branchAreaId = undefined;
  }

  setNumberOfUnits = numberOfUnits => {
    this.numberOfUnits = numberOfUnits;
    return this;
  };

  getNumberOfUnits() {
    return this.numberOfUnits;
  }

  setUnit = unit => {
    this.unit = unit;
    return this;
  };

  getUnit() {
    return this.unit;
  }

  setBranchName = branchName => {
    this.branchName = branchName;
    return this;
  };

  getBranchName() {
    return this.branchName;
  }

  setBranchAreaId = id => {
    this.branchAreaId = id;
    return this;
  };

  getBranchAreaId = () => this.branchAreaId;

  setTotalPrice = totalPrice => {
    this.totalPrice = totalPrice;
    return this;
  };

  getTotalPrice() {
    return this.totalPrice;
  }

  setPaymentCycle = paymentCycle => {
    this.paymentCycle = paymentCycle;
    return this;
  };

  getPaymentCycle() {
    return this.paymentCycle;
  }
}

export default Item;
