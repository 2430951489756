import BillingCurrency from './BillingCurrency';
import LocalCurrency from './LocalCurrency';
import PhoneRegex from './PhoneRegex';

class Country {
  constructor() {
    this.currentCountry = false;
    this.countryId = undefined;
    this.nameEnglish = undefined;
    this.nameArabic = undefined;
    this.imageUrl = undefined;
    this.timeZone = undefined;
    this.countryCode = undefined;
    this.countryCodeId = undefined;
    this.longitude = undefined;
    this.latitude = undefined;
    this.localCurrencyId = undefined;
    this.billingCurrencyId = undefined;
    this.subDomain = undefined;
    this.isoCode = undefined;
    this.systemTimeZone = undefined;
    this.hotLine = undefined;
    this.isSmsVerificationEnabled = undefined;
    this.customerServiceEmail = undefined;
    this.salesEmail = undefined;
    this.billingCurrency = new BillingCurrency();
    this.localCurrency = new LocalCurrency();
    this.phoneRegex = [];
  }

  setCurrentCountry = bool => {
    this.currentCountry = bool;
  };

  getCurrentCountry() {
    return this.currentCountry;
  }

  setCountryId = countryId => {
    this.countryId = countryId;
    return this;
  };

  getCountryId() {
    return this.countryId;
  }

  setNameEnglish = nameEnglish => {
    this.nameEnglish = nameEnglish;
    return this;
  };

  getNameEnglish() {
    return this.nameEnglish;
  }

  setNameArabic = nameArabic => {
    this.nameArabic = nameArabic;
    return this;
  };

  getNameArabic() {
    return this.nameArabic;
  }

  setImageUrl = imageUrl => {
    this.imageUrl = imageUrl;
    return this;
  };

  getImageUrl() {
    return this.imageUrl;
  }

  setTimeZone = timeZone => {
    this.timeZone = timeZone;
    return this;
  };

  getTimeZone() {
    return this.timeZone;
  }

  setCountryCode = countryCode => {
    this.countryCode = countryCode;
    return this;
  };

  getCountryCode() {
    return this.countryCode;
  }

  setCountryCodeId = countryCodeId => {
    this.countryCodeId = countryCodeId;
    return this;
  };

  getCountryCodeId() {
    return this.countryCodeId;
  }

  setLongitude = longitude => {
    this.longitude = longitude;
    return this;
  };

  getLongitude() {
    return this.longitude;
  }

  setLatitude = latitude => {
    this.latitude = latitude;
    return this;
  };

  getLatitude() {
    return this.latitude;
  }

  setLocalCurrencyId = localCurrencyId => {
    this.localCurrencyId = localCurrencyId;
    return this;
  };

  getLocalCurrencyId() {
    return this.localCurrencyId;
  }

  setBillingCurrencyId = billingCurrencyId => {
    this.billingCurrencyId = billingCurrencyId;
    return this;
  };

  getBillingCurrencyId() {
    return this.billingCurrencyId;
  }

  setSubDomain = subDomain => {
    this.subDomain = subDomain;
    return this;
  };

  getSubDomain() {
    return this.subDomain;
  }

  setIsoCode = isoCode => {
    this.isoCode = isoCode;
    return this;
  };

  getIsoCode() {
    return this.isoCode;
  }

  setSystemTimeZone = systemTimeZone => {
    this.systemTimeZone = systemTimeZone;
    return this;
  };

  getSystemTimeZone() {
    return this.systemTimeZone;
  }

  setHotLine = hotLine => {
    this.hotLine = hotLine;
    return this;
  };

  getHotLine() {
    return this.hotLine;
  }

  setIsSmsVerificationEnabled = isSmsVerificationEnabled => {
    this.isSmsVerificationEnabled = isSmsVerificationEnabled;
    return this;
  };

  getIsSmsVerificationEnabled() {
    return this.isSmsVerificationEnabled;
  }

  setCustomerServiceEmail = customerServiceEmail => {
    this.customerServiceEmail = customerServiceEmail;
    return this;
  };

  getCustomerServiceEmail() {
    return this.customerServiceEmail;
  }

  setSalesEmail = salesEmail => {
    this.salesEmail = salesEmail;
    return this;
  };

  getSalesEmail() {
    return this.salesEmail;
  }

  getBillingCurrencyModel = () => this.billingCurrency;

  getLocalCurrencyModel = () => this.localCurrency;

  /**
   * Create a new phone regex and returns it
   */
  addPhoneRegex = () => {
    const newPhoneRegex = new PhoneRegex();
    this.phoneRegex.push(newPhoneRegex);
    return newPhoneRegex;
  };
}

export default Country;
