/* eslint-disable indent */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import {
  DropDown,
  ShimmerEffect,
  NoAnimationFlex,
  Rect,
  Circle,
  UserPreview,
  Image,
  IconsStore,
} from '@vezeeta/web-components';
import Icons from '@vezeeta/web-icons';
import { HeaderContainer, HeadersTitles, DoctorName, VezeetaLogo } from './Header.style';
import './Header.css';

const Header = props => {
  const {
    doctorPhoto,
    doctorName,
    doctorEmail,
    title,
    tabs,
    cdnUrl,
    onSignOut,
    language,
    user,
    onLanguageChange,
    supportSingleLanguage,
    returnToHome,
  } = props;
  let headerItems;
  let doctorDetails;
  const isMobile = window.innerWidth <= 1023;

  if (title) {
    headerItems = <HeadersTitles className="page-title">{title}</HeadersTitles>;
  } else if (tabs.length !== 0) {
    headerItems = tabs.map(
      item =>
        item.enable && (
          <NavLink
            className="tab"
            activeClassName="tab--active"
            href={item.url}
            to={item.url}
            key={item.title}
          >
            <HeadersTitles cursor="pointer" className="text">
              {item.title}
            </HeadersTitles>
          </NavLink>
        ),
    );
  }

  const doctorImage =
    doctorPhoto !== '' ? doctorPhoto : `${cdnUrl}/assets/user-placeholder-blue.png`;

  const { CountryIsoCode } = user;

  const onClickHotLine = number => {
    window.location = `tel:${number}`;
  };

  if (doctorName && !doctorName.includes('undefined')) {
    let items = [
      {
        component: (
          <UserPreview
            name={doctorName}
            email={doctorEmail}
            photo={doctorImage}
            fallbackPhoto={`${cdnUrl}/assets/user-placeholder.png`}
            reverse={language !== 'en'}
          />
        ),
      },
      {
        isDivider: true,
      },
      {
        text: language === 'en' ? 'Return Home' : 'العودة للصفحة الرئيسية',
        onClick: () => returnToHome(),
      },
    ];

    if (!supportSingleLanguage) {
      const options = [
        {
          name: language === 'en' ? 'English' : 'الإنجليزية',
          key: 'en',
          isSelected: language === 'en',
        },
        {
          name: language === 'en' ? 'Arabic' : 'العربية',
          key: 'ar',
          isSelected: language === 'ar',
        },
      ];

      items.push({
        text: language === 'en' ? 'Language: English' : 'اللغة: العربية',
        onClick: () => onLanguageChange(language),
        disabled: false,
        isExpandable: true,
        options,
      });
    }

    items =
      window.hotLines[CountryIsoCode] && window.hotLines[CountryIsoCode][language]
        ? [
            ...items,
            {
              isDivider: true,
            },
            {
              // icon: window.hotLines[CountryIsoCode] ? IconsStore.getIcon('phone') : null,
              text: window.hotLines[CountryIsoCode]
                ? `${language === 'en' ? 'Support: ' : 'الدعم: '} ${
                    window.hotLines[CountryIsoCode][language]
                  }`
                : null,
              onClick: () => onClickHotLine(window.hotLines[CountryIsoCode].en),
              isBold: true,
              isHighlighted: true,
            },
            {
              isDivider: true,
            },
            {
              // icon: IconsStore.getIcon('logout'),
              text: language === 'en' ? 'Logout' : 'تسجيل الخروج',
              onClick: onSignOut,
            },
          ]
        : [
            ...items,
            {
              isDivider: true,
            },
            {
              icon: new IconsStore(Icons).getIcon('logout'),
              text: language === 'en' ? 'Logout' : 'تسجيل الخروج',
              onClick: onSignOut,
            },
          ];

    doctorDetails = (
      <Fragment>
        <DropDown
          items={items}
          menuAlignment={language === 'en' ? 'RIGHT' : 'LEFT'}
          component={
            <Fragment>
              <DoctorName language={language}>{doctorName}</DoctorName>
              <Image
                src={doctorImage}
                alt="doctor"
                fallback={`${cdnUrl}/assets/user-placeholder-blue.png`}
                radius={30}
                name={doctorName}
              />
            </Fragment>
          }
        />
      </Fragment>
    );
  } else {
    doctorDetails = (
      <ShimmerEffect>
        <NoAnimationFlex className="doctor-info--loading">
          {!isMobile && <Rect mr={3} className="doctor-name" />}
          <Circle className="doctor-photo" />
        </NoAnimationFlex>
      </ShimmerEffect>
    );
  }

  return (
    <HeaderContainer language={language}>
      <VezeetaLogo
        src={`${process.env.REACT_APP_CDN_URL}/assets/logo.png`}
        alt="logo"
        onClick={() => {
          returnToHome();
        }}
      />
      <div className="header-tabs--container">{headerItems}</div>
      <div className="doctor-info--container">{doctorDetails}</div>
    </HeaderContainer>
  );
};

Header.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      url: PropTypes.string,
      enable: PropTypes.bool,
    }),
  ),
  title: PropTypes.string,
  doctorName: PropTypes.string,
  doctorEmail: PropTypes.string,
  doctorPhoto: PropTypes.string,
  onSignOut: PropTypes.func.isRequired,
  cdnUrl: PropTypes.string.isRequired,
  language: PropTypes.string.isRequired,
  user: PropTypes.object,
  onLanguageChange: PropTypes.func.isRequired,
  supportSingleLanguage: PropTypes.bool.isRequired,
  returnToHome: PropTypes.func.isRequired,
};

Header.defaultProps = {
  doctorName: '',
  doctorEmail: '',
  doctorPhoto: '',
  title: undefined,
  tabs: undefined,
  user: undefined,
};

export default Header;
