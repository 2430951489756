class BillingCurrency {
  constructor() {
    this.currencyId = undefined;
    this.nameEnglish = undefined;
    this.nameArabic = undefined;
  }

  setCurrencyId = currencyId => {
    this.currencyId = currencyId;
    return this;
  };

  getCurrencyId() {
    return this.currencyId;
  }

  setNameEnglish = nameEnglish => {
    this.nameEnglish = nameEnglish;
    return this;
  };

  getNameEnglish() {
    return this.nameEnglish;
  }

  setNameArabic = nameArabic => {
    this.nameArabic = nameArabic;
    return this;
  };

  getNameArabic() {
    return this.nameArabic;
  }
}

export default BillingCurrency;
