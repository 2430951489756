/*eslint-disable*/

import Loadable from 'react-loadable';
import { Loading } from '@vezeeta/web-components';

// Async loading components
export const Signup = Loadable({
  loader: () => import('views/signup/Signup'),
  loading: Loading,
});

export const Login = Loadable({
  loader: () => import('views/login/Login'),
  loading: Loading,
});

export const ForgetPassword = Loadable({
  loader: () => import('views/forgetPassword/ForgetPassword'),
  loading: Loading,
});

export const Invoice = Loadable({
  loader: () => import('views/invoice/Invoice'),
  loading: Loading,
});

export const Subscription = Loadable({
  loader: () => import('views/subscription/subscription'),
  loading: Loading,
});

export const Routes = [
  {
    route: '/login',
  },
  {
    route: '/signup',
  },
  {
    route: '/forget-password',
  },
  {
    route: '/invoice',
  },
  {
    route: '/subscriptions',
  },
];

