class FeesModel {
  constructor() {
    this.value = undefined;
    this.isPercentage = undefined;
    this.paymentAttributeTypeId = undefined;
    this.paymentAttributeType = undefined;
    this.allowedPaymentPlans = [];
  }

  setValue = value => {
    this.value = value;
    return this;
  };

  getValue() {
    return this.value;
  }

  setIsPercentage = isPercentage => {
    this.isPercentage = isPercentage;
    return this;
  };

  getIsPercentage() {
    return this.isPercentage;
  }

  setPaymentAttributeTypeId = paymentAttributeTypeId => {
    this.paymentAttributeTypeId = paymentAttributeTypeId;
    return this;
  };

  getPaymentAttributeTypeId() {
    return this.paymentAttributeTypeId;
  }

  setPaymentAttributeType = paymentAttributeType => {
    this.paymentAttributeType = paymentAttributeType;
    return this;
  };

  getPaymentAttributeType() {
    return this.paymentAttributeType;
  }

  setAllowedPaymentPlans = allowedPaymentPlans => {
    this.allowedPaymentPlans = allowedPaymentPlans;
    return this;
  };

  getAllowedPaymentPlans() {
    return this.allowedPaymentPlans;
  }
}

export default FeesModel;
