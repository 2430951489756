/* eslint-disable camelcase */
/* global process */
import React, { PureComponent } from 'react';
import { BrowserRouter as Router, Switch, Redirect } from 'react-router-dom';
import { API, STATUS, Cookie, Urls } from '@vezeeta/web-utils';
import { Spinner, FeatureFlagContainer, SetGlobalStyle } from '@vezeeta/web-components';
import { Authenticate } from '@vezeeta/web-sso';
import '@vezeeta/web-components/lib/shared/Utils.css';
import { Signup, Login, ForgetPassword, Invoice, Routes, Subscription } from 'app/Routes';
import { Culture } from 'app/helpers';
import { withTranslation } from 'app/withTranslation/withTranslation';

import { Countries, Config, User } from 'views/signup/_objects/_Objects';
import { MixpanelConsumer } from 'react-mixpanel';
import Layout from 'app/Layout';
import AppError from './AppError';

import HeaderSelector from './HeaderSelector';
import { integrateRaygun, integrateSalesforce } from './SDKIntegrations';
import { getFeatureFlags, subsciptionPageHandler } from './App.helper';
import './App.css';
import UseRoute from './UseRoute';

class App extends PureComponent {
  constructor() {
    super();

    SetGlobalStyle();

    this.countries = Countries.getInstance();
    this.config = Config.getInstance();
    this.user = User.getInstance();
    this.shouldLoadCountries = window.location.pathname.includes('signup');

    this.state = {
      isCountriesLoading: this.shouldLoadCountries,
      enableEnterpriseLayout: undefined,
    };
  }

  componentDidMount() {
    // Set the background image
    document.body.style.backgroundImage = `url("${
      process.env.REACT_APP_CDN_URL
    }/assets/background-sm.png")`;

    this.backgroundImg.onload = () => {
      document.body.style.backgroundImage = `url("${
        process.env.REACT_APP_CDN_URL
      }/assets/background.png")`;
    };

    if (window.location.pathname !== '/invoice' && window.location.pathname !== '/subscriptions') {
      // Check if the user is already signed in
      const isAuthenticated = Cookie.get(Cookie.AUTH_TOKEN);
      const isTemporarilyEdit = !!(Cookie.get(Cookie.START_ROUTE) && Cookie.get(Cookie.END_ROUTE));

      if (isAuthenticated && !isTemporarilyEdit) {
        Authenticate(() => {
          window.location.href = process.env.REACT_APP_SCHEDULE_URL;
        });
      } else if (this.shouldLoadCountries) {
        this.loadCountries();
      }
    }
  }

  onRouteChange = (moduleName, pathName) => {
    if (String(pathName).includes('subscriptions')) {
      this.setState({ enableEnterpriseLayout: true });
    } else {
      this.setState({ enableEnterpriseLayout: false });
    }
  };

  static getDerivedStateFromProps() {
    if (window.location.pathname === '/') {
      window.location = `${process.env.REACT_APP_ACCOUNTS_URL}/login`;
    }

    if (window.location.pathname === '/subscriptions') {
      subsciptionPageHandler();
    }

    integrateRaygun();
    integrateSalesforce();
    return null;
  }

  loadCountries = () => {
    if (!this.countries.isLoaded()) {
      this.setState({
        isCountriesLoading: true,
      });
      const getCountries = new API();
      const detectCountry = new API();
      const allowedRoutes = [];
      Routes.map(route => allowedRoutes.push(route.route));
      getCountries
        // eslint-disable-next-line no-underscore-dangle
        .get(Urls.country.getCountries, undefined, true)
        .then(response => {
          this.countries.loadCountries(response.data);
        })
        .then(async () => {
          if (!this.user.isAlreadyRegistered()) {
            if (Culture.isCultureSpecified()) {
              if (this.countries.isCountrySupported(Culture.getCountry())) {
                if (Culture.getLanguage() !== 'en') {
                  this.changeCulture(Culture.getCountry());
                }
                this.countries.currentCountry(Culture.getCountry());
                this.updateConfig(Culture.getCountry());
                this.setState({
                  isCountriesLoading: false,
                });
              } else {
                this.changeCulture(this.config.getCountryIsoCode());
              }
            } else {
              detectCountry.get(`${Urls.country.detectCountry}`).then(response => {
                if (STATUS.isSuccess(response.status)) {
                  this.countries.currentCountry(response.data.ISOCode);
                  this.updateConfig(response.data.ISOCode);
                }

                this.changeCulture(this.config.getCountryIsoCode());
                this.setState({
                  isCountriesLoading: false,
                });
              });
            }
          } else if (!this.user.isLoaded()) {
            await this.user.loadUserData();
            this.countries.currentCountry(this.user.getCountryIsoCode());
            this.updateConfig(this.user.getCountryIsoCode());

            this.changeCulture(this.config.getCountryIsoCode());
            this.setState({
              isCountriesLoading: false,
            });
          }
        });
    }
  };

  updateConfig = countryIso => {
    const currentCountry = this.countries.getCountryByIso(countryIso);
    this.countries.currentCountry(currentCountry);

    this.config
      .setCountryId(currentCountry.getCountryId())
      .setCountryIsoCode(currentCountry.getIsoCode())
      .setCountryName(currentCountry.getNameEnglish())
      .setCulture(`en-${currentCountry.getIsoCode()}`)
      .setLocalCurrency(currentCountry.getLocalCurrencyModel().getNameEnglish())
      .setLocalCurrencyId(currentCountry.getLocalCurrencyModel().getCurrencyId())
      .setBillingCurrency(currentCountry.getBillingCurrencyModel().getNameEnglish())
      .setBillingCurrencyId(currentCountry.getBillingCurrencyModel().getCurrencyId())
      .setCountryCodeId(currentCountry.getCountryCodeId());

    this.user.setCountryCodeId(this.config.getCountryCodeId());
  };

  changeCulture = countryIso => {
    if (window.location.pathname.includes('signup')) {
      if (Culture.getCountry() !== countryIso.toLowerCase() || Culture.getLanguage() !== 'en') {
        let pathname = Culture.getPathnameWithoutCulture(window.location.pathname);
        const slashIndex = pathname.indexOf('/');

        if (slashIndex === 0) {
          pathname = pathname.replace('/', '');
        }
        window.location.pathname = `en-${countryIso.toLowerCase()}/${pathname}`;
      }
    }
  };

  render() {
    const { isCountriesLoading, enableEnterpriseLayout } = this.state;
    const { localization } = this.props;
    return (
      <Layout>
        <AppError>
          <Router history={this.browserHistory} basename={`/${Culture.getCulture()}`}>
            <div className="App">
              <img
                src={`${process.env.REACT_APP_CDN_URL}/assets/background.png`}
                ref={img => {
                  this.backgroundImg = img;
                }}
                className="hide"
                alt="logo"
              />
              <HeaderSelector
                enableEnterpriseLayout={enableEnterpriseLayout}
                isLoadingCountries={this.state.isCountriesLoading}
                loadCountries={this.loadCountries}
                changeCulture={this.changeCulture}
                shouldShowCounties={this.shouldLoadCountries}
                updateConfig={this.updateConfig}
                supportSingleLanguage
                localization={localization}
              >
                <FeatureFlagContainer.Provider value={getFeatureFlags()}>
                  {!isCountriesLoading ? (
                    <MixpanelConsumer>
                      {mixpanel => (
                        <Switch>
                          <UseRoute
                            moduleName="login"
                            onChange={this.onRouteChange}
                            path="/login"
                            render={routeProps => (
                              <Login {...routeProps} {...this.props} localization={localization} />
                            )}
                          />

                          <UseRoute
                            moduleName="signup"
                            onChange={this.onRouteChange}
                            path="/signup"
                            render={routeProps => (
                              <Signup {...routeProps} {...this.props} localization={localization} />
                            )}
                          />

                          <UseRoute
                            moduleName="forget-password"
                            onChange={this.onRouteChange}
                            path="/forget-password"
                            render={routeProps => (
                              <ForgetPassword
                                {...routeProps}
                                {...this.props}
                                localization={localization}
                              />
                            )}
                          />

                          <UseRoute
                            moduleName="invoice"
                            onChange={this.onRouteChange}
                            path="/invoice"
                            render={routeProps => (
                              <Invoice
                                {...routeProps}
                                {...this.props}
                                localization={localization}
                              />
                            )}
                          />

                          <UseRoute
                            moduleName="subscriptions"
                            onChange={this.onRouteChange}
                            path="/subscriptions"
                            render={routeProps => (
                              <Subscription
                                {...routeProps}
                                {...this.props}
                                mixpanel={mixpanel}
                                localization={localization}
                              />
                            )}
                          />
                          <Redirect to="/login" />
                        </Switch>
                      )}
                    </MixpanelConsumer>
                  ) : (
                    <div className="loading-routes">
                      <Spinner />
                    </div>
                  )}
                </FeatureFlagContainer.Provider>
              </HeaderSelector>
            </div>
          </Router>
        </AppError>
      </Layout>
    );
  }
}

export default withTranslation(App);
