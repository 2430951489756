class Pricing {
  constructor() {
    this.priceTypeId = undefined;
    this.name = undefined;
  }

  setPriceTypeId = priceTypeId => {
    this.priceTypeId = priceTypeId;
    return this;
  };

  getPriceTypeId() {
    return this.priceTypeId;
  }

  setName = name => {
    this.name = name;
    return this;
  };

  getName() {
    return this.name;
  }
}

export default Pricing;
