import PaymentMethod from '../paymentTypes/PaymentMethod';

class PaymentType {
  constructor() {
    this.name = undefined;
    this.description = undefined;
    this.paymentTypeKey = undefined;
    this.paymentMethods = [];
  }

  setName = name => {
    this.name = name;
    return this;
  };

  getName() {
    return this.name;
  }

  setDescription = description => {
    this.description = description;
    return this;
  };

  getDescription() {
    return this.description;
  }

  setPaymentTypeKey = paymentTypeKey => {
    this.paymentTypeKey = paymentTypeKey;
    return this;
  };

  getPaymentTypeKey() {
    return this.paymentTypeKey;
  }

  getPaymentMethods() {
    return this.paymentMethods;
  }

  /**
   * Create a payment type's payment methods and returns it
   */
  addPaymentMethod = () => {
    const newPaymentMethod = new PaymentMethod();
    this.paymentMethods.push(newPaymentMethod);
    return newPaymentMethod;
  };
}

export default PaymentType;
