import { isFound } from '@vezeeta/web-utils';

import ProductLineDetails from './ProductLineDetails';

class ProductLine {
  constructor() {
    this.registered = false;
    this.productLineId = undefined;
    this.nameEnglish = undefined;
    this.nameArabic = undefined;
    this.descriptionEnglish = undefined;
    this.descriptionArabic = undefined;
    this.image = undefined;
    this.icon = undefined;
    this.productLineDetails = [];
  }

  register = () => {
    this.registered = true;
  };

  unRegister() {
    this.registered = false;
  }

  isRegistered() {
    return this.registered;
  }

  setProductLineId = productLineId => {
    this.productLineId = productLineId;
    return this;
  };

  getProductLineId() {
    return this.productLineId;
  }

  setNameEnglish = nameEnglish => {
    this.nameEnglish = nameEnglish;
    return this;
  };

  getNameEnglish() {
    return this.nameEnglish;
  }

  setNameArabic = nameArabic => {
    this.nameArabic = nameArabic;
    return this;
  };

  getNameArabic() {
    return this.nameArabic;
  }

  setDescriptionEnglish = descriptionEnglish => {
    this.descriptionEnglish = descriptionEnglish;
    return this;
  };

  getDescriptionEnglish() {
    return this.descriptionEnglish;
  }

  setDescriptionArabic = descriptionArabic => {
    this.descriptionArabic = descriptionArabic;
    return this;
  };

  getDescriptionArabic() {
    return this.descriptionArabic;
  }

  setIcon = icon => {
    this.icon = icon;
    return this;
  };

  getIcon() {
    return this.icon;
  }

  setImage = image => {
    this.image = image;
    return this;
  };

  getImage() {
    return this.image;
  }

  /**
   * Create a new product line details and returns it
   */
  addProductLineDetails = () => {
    const newProductLineDetails = new ProductLineDetails();
    this.productLineDetails.push(newProductLineDetails);
    return newProductLineDetails;
  };

  getProductLineDetailsByPaymentCycleId(paymentCycleId) {
    const result = this.productLineDetails.filter(
      productLineDetails => productLineDetails.getPaymentCycleId() === paymentCycleId,
    );

    if (isFound(result)) {
      return result[0];
    }
  }

  getProductLineDetailsBySpecialtyGroupId(specialtyGroupId) {
    const result = this.productLineDetails.filter(
      productLineDetails => productLineDetails.getSpecialtyGroupId() === specialtyGroupId,
    );

    if (isFound(result)) {
      return result[0];
    }
  }

  getProductLinesDetailsByPriceTypeId(priceTypeId) {
    const result = this.productLineDetails.filter(
      productLineDetails => productLineDetails.getPriceTypeId() === priceTypeId,
    );

    if (isFound(result)) {
      return result;
    }
  }

  getProductLineDetailsByPriceTypeIdAndSpecialtyGroupId(priceTypeId, specialtyGroupId) {
    const result = this.productLineDetails.filter(
      productLineDetails =>
        productLineDetails.getPriceTypeId() === priceTypeId &&
        productLineDetails.getSpecialtyGroupId() === specialtyGroupId,
    );

    if (isFound(result)) {
      return result[0];
    }
  }
}

export default ProductLine;
