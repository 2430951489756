/* eslint-disable import/prefer-default-export */
import { Cookie, encrypt, decodeJwtToken } from '@vezeeta/web-utils';

export const getFeatureFlags = () => ({
  enableSkipPayment: window.enableSkipPayment || Cookie.get(Cookie.ENABLE_SKIP_PAYMENT) === 'true',
});

export const subsciptionPageHandler = () => {
  if (window.location.pathname === '/subscriptions') {
    if (!Cookie.get(Cookie.AUTH_TOKEN)) {
      Cookie.set(Cookie.RETURN_URL, process.env.REACT_APP_SCHEDULE_URL);
      Cookie.set(Cookie.START_ROUTE, '/subscriptions');
      Cookie.set(Cookie.END_ROUTE, '/subscriptions');
      Cookie.set(
        Cookie.RETURN_URL,
        `${process.env.REACT_APP_ACCOUNTS_URL.toString()}/subscriptions`,
      );
      window.location.href = process.env.REACT_APP_ACCOUNTS_URL;
    } else {
      const accountKey = decodeJwtToken(Cookie.get(Cookie.AUTH_TOKEN)).payLoad.unique_name;
      Cookie.set(Cookie.ACCOUNT_KEY, encrypt(accountKey));
    }
  }
};
