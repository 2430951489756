/* eslint-disable camelcase */
import { API, Urls } from '@vezeeta/web-utils';

//* *********************************************//
//* *******************Raygun*******************//
//* ********************************************//
export const integrateRaygun = () => {
  const { rg4js, enableRaygun } = window;
  if (
    rg4js &&
    typeof rg4js === 'function' &&
    enableRaygun &&
    process.env.REACT_APP_CONFIG_ENV === 'production'
  ) {
    rg4js('apiKey', process.env.REACT_APP_RAYGUN_API_KEY);
    rg4js('enableCrashReporting', true);
    // rg4js('enablePulse', false); // Enables Real User Monitoring
    rg4js('setUser', {
      isAnonymous: true,
    });
    rg4js('options', {
      allowInsecureSubmissions: false,
    });
  }
};

//* *********************************************//
//* **************Salesforce chat***************//
//* ********************************************//
const localizedButtonIDChat = {
  1: process.env.REACT_APP_CHAT_BUTTON_ID_EG,
  4: process.env.REACT_APP_CHAT_BUTTON_ID_SA,
};

const localizedDeploymentId = {
  1: process.env.REACT_APP_DEPLOYMENT_ID_EG,
  4: process.env.REACT_APP_DEPLOYMENT_ID_SA,
};

const localizedEswLiveAgentDevName = {
  1: process.env.REACT_APP_ESWLIVE_AGENT_DEV_NAME_EG,
  4: process.env.REACT_APP_ESWLIVE_AGENT_DEV_NAME_SA,
};

const localizedDeploymentName = {
  1: process.env.REACT_APP_DEPLOYMENT_NAME_EG,
  4: process.env.REACT_APP_DEPLOYMENT_NAME_SA,
};

export const integrateSalesforce = () => {
  const { embedded_svc, detectedIP } = window;
  if (embedded_svc && detectedIP) {
    const detectCountry = new API();
    detectCountry.get(`${Urls.country.detectCountry}${detectedIP}`).then(response => {
      const { data } = response;
      embedded_svc.settings.storageDomain = process.env.REACT_APP_TOKEN_DOMAIN;
      embedded_svc.settings.language = 'en';

      embedded_svc.settings.defaultMinimizedText = 'Chat with an expert';
      embedded_svc.settings.disabledMinimizedText = 'Agent Offline';
      window.localizedButtonId =
        data && data.CountryId && window && window.localizedButtonIDChat[data.CountryId];
      window.localizedButtonId = data && data.CountryId && localizedButtonIDChat[data.CountryId];
      window.localizedDeploymentId =
        data && data.CountryId && localizedDeploymentId[data.CountryId];
      window.localizedEswLiveAgentDevName =
        data && data.CountryId && localizedEswLiveAgentDevName[data.CountryId];
      window.localizedDeploymentName =
        data && data.CountryId && localizedDeploymentName[data.CountryId];

      window.enableChat =
        window && window.localizedButtonId && window.countriesSupportChat.includes(data.CountryId);
    });
  }
};
