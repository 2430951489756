const cultureRegex = '^[a-z]{2,3}(?:-[a-z]{2,3}(?:-[a-zA-Z]{4})?)?$';
const slashRegex = new RegExp('/', 'g');

class Culture {
  static cultureSpecified;
  static culture;

  static getCulture = () => {
    const firstRoute = window.location.pathname.split('/')[1];
    Culture.cultureSpecified = firstRoute.match(cultureRegex);

    if (Culture.cultureSpecified) {
      Culture.culture = firstRoute;
      return Culture.culture;
    }
    return '';
  };

  static getCountry = () => {
    if (Culture.cultureSpecified) {
      return Culture.culture.split('-')[1];
    }
  };

  static getLanguage = () => {
    if (Culture.cultureSpecified) {
      return Culture.culture.split('-')[0];
    }
  };

  static removeCultureFromUrl = url =>
    url.replace(slashRegex, '').replace(Culture.getCulture(), '');

  static getPathnameWithoutCulture = url => url.replace(Culture.getCulture(), '').replace('/', '');

  static isCultureSpecified = () => {
    Culture.getCulture();
    return Culture.cultureSpecified;
  };
}

export default Culture;
