import { API, isFound, fixFloat, Urls } from '@vezeeta/web-utils';

let instance = null;

class CurrencyConverter {
  constructor() {
    this.isCached = false;
    this.currenciesTable = [];
  }

  static getInstance() {
    if (!instance) {
      instance = new CurrencyConverter();
    }

    return instance;
  }

  loadCurrencyConversions = async () => {
    await new Promise(resolve => {
      if (!this.isCached) {
        const currencyConversionApi = new API();
        currencyConversionApi.get(Urls.currencyConversion).then(response => {
          response.data.forEach(currencyRow => {
            this.currenciesTable.push({
              fromCurrencyId: currencyRow.FromCurrencyId,
              toCurrencyId: currencyRow.ToCurrencyId,
              rate: currencyRow.Rate,
            });
          });

          this.isCached = true;
          resolve();
        });
      } else {
        resolve();
      }
    });
  };

  convertCurrency = (fromCurrencyId, toCurrencyId, amount) => {
    const result = this.currenciesTable.filter(
      currencyRow =>
        currencyRow.fromCurrencyId === fromCurrencyId && currencyRow.toCurrencyId === toCurrencyId,
    );

    if (isFound(result)) {
      const converted = amount * result[0].rate;
      return fixFloat(converted, 2);
    }
    return amount;
  };
}

export default CurrencyConverter;
