import { isFound } from '@vezeeta/web-utils';

import Country from './country/Country';

let instance = null;

class Countries {
  constructor() {
    this.cached = false;
    this.countries = [];
  }

  static getInstance() {
    if (!instance) {
      instance = new Countries();
    }

    return instance;
  }

  isLoaded = () => this.cached;

  /**
   * Gets countries from getCountries api
   */
  loadCountries = countries => {
    countries.forEach(country => {
      const localCurrency = country.CountryCourrencies.CurrencyModels.find(
        model => model.IsDefault === true,
      );
      const billingCurrency = country.CountryCourrencies.CurrencyModels.find(
        model => model.IsBillingCurrency === true,
      );
      const newCountry = this.addCountry();
      newCountry
        .setCountryId(country.CountryId)
        .setNameEnglish(country.LanguageItemModels.find(model => model.LanguageId === 1).Name)
        .setNameArabic(country.LanguageItemModels.find(model => model.LanguageId === 2).Name)
        .setImageUrl(country.IconUrl)
        .setTimeZone(country.Timezone)
        .setCountryCode(country.CountryCode)
        .setCountryCodeId(country.CountryCodeId)
        .setLongitude(country.Longitude)
        .setLatitude(country.Latitude)
        .setLocalCurrencyId(localCurrency.Id)
        .setBillingCurrencyId(billingCurrency.Id)
        .setSubDomain(country.SubDomain)
        .setIsoCode(country.ISOCode)
        .setSystemTimeZone(country.SystemTimeZone)
        .setHotLine(country.Hotline)
        .setIsSmsVerificationEnabled(country.IsSmsVerificationEnabled)
        .setCustomerServiceEmail(country.CustomerServiceEmail)
        .setSalesEmail(country.SalesEmail)
        .getBillingCurrencyModel()
        .setCurrencyId(billingCurrency.Id)
        .setNameEnglish(
          billingCurrency.LanguageItemModels.find(model => model.LanguageId === 1).Name,
        )
        .setNameArabic(
          billingCurrency.LanguageItemModels.find(model => model.LanguageId === 2).Name,
        );

      newCountry
        .getLocalCurrencyModel()
        .setCurrencyId(localCurrency.Id)
        .setNameEnglish(localCurrency.LanguageItemModels.find(model => model.LanguageId === 1).Name)
        .setNameArabic(localCurrency.LanguageItemModels.find(model => model.LanguageId === 1).Name);

      country.PhoneRegexes.forEach(phoneRegex => {
        const newPhoneRegex = newCountry.addPhoneRegex();
        newPhoneRegex
          .setPhoneRegexId(phoneRegex.PhoneRegexId)
          .setCountryId(phoneRegex.CountryId)
          .setRegex(phoneRegex.Regex)
          .setRegexTypeId(phoneRegex.RegexTypeId);
      });
    });

    this.cached = true;
  };

  getCountries() {
    return this.countries;
  }

  /**
   * Create a new countries and returns it
   */
  addCountry = () => {
    const newCountry = new Country();
    this.countries.push(newCountry);
    return newCountry;
  };

  currentCountry(isoCode) {
    this.countries.forEach(country => {
      if (country.getIsoCode() === isoCode) {
        country.setCurrentCountry(true);
      } else {
        country.setCurrentCountry(false);
      }
    });
  }

  getCountryById(countryId) {
    const result = this.countries.filter(country => country.getCountryId() === countryId);

    if (isFound(result)) {
      return result[0];
    }
  }

  getCountryByIso(countryIso) {
    const result = this.countries.filter(
      country => country.getIsoCode().toLowerCase() === countryIso.toLowerCase(),
    );

    if (isFound(result)) {
      return result[0];
    }
  }

  isCountrySupported(countryIso) {
    const result = this.countries.filter(
      country => country.getIsoCode().toLowerCase() === countryIso.toLowerCase(),
    );

    if (isFound(result)) {
      return true;
    }
    return false;
  }
}

export default Countries;
