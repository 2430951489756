import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Splash from '@vezeeta/web-components/lib/splash/Splash';
import { BrowserRouter as Router } from 'react-router-dom';
import { Cookie } from '@vezeeta/web-utils';
import Header from './app/components/header/Header';
import logoUrl from './app/assets/images/logo-blue.png';
import { ContentContainer, LayoutContainer } from './EnterpriseLayout.styles';
import './EnterpriseLayout.css';

class EnterpriseLayout extends Component {
  onSignOut = () => {
    Cookie.getAllCookies().forEach(cookie =>
      Cookie.remove(cookie, { domain: process.env.REACT_APP_TOKEN_DOMAIN }),
    );
    // Note: next line must be moved to web-utils cookie removal
    Cookie.remove(Cookie.TEMP_AUTH_TOKEN, { domain: process.env.REACT_APP_TOKEN_DOMAIN });
    window.location.reload(true);
    this.props.signOut();
  };

  returnToHome = () => {
    const returnUrl = Cookie.get(Cookie.RETURN_URL);
    if (returnUrl) {
      const cookiesToBeKept = [Cookie.OLD_AUTH_TOKEN, Cookie.AUTH_TOKEN, Cookie.CULTURE];
      Cookie.getAllCookies().forEach(cookie => {
        if (!cookiesToBeKept.includes(cookie)) {
          Cookie.remove(cookie, {
            domain: process.env.REACT_APP_TOKEN_DOMAIN,
          });
        }
      });
      window.location = returnUrl;
    } else {
      window.location.pathname = '/login';
    }
  };

  render() {
    const {
      children,
      language,
      onLanguageChange,
      loading,
      supportSingleLanguage,
      extendContentContainer,
    } = this.props;
    if (loading) {
      return <Splash logoUrl={logoUrl} />;
    }
    return (
      <Router history={this.browserhistory}>
        <LayoutContainer language={language}>
          <Header
            onLogoClick={() => {
              window.location.pathname = Cookie.get(Cookie.RETURN_URL);
            }}
            supportSingleLanguage={supportSingleLanguage}
            onLanguageChange={onLanguageChange}
            onSignOut={this.onSignOut}
            returnToHome={this.returnToHome}
            {...this.state}
            {...this.props}
          />
          <ContentContainer extendContentContainer={extendContentContainer}>
            {children}
          </ContentContainer>
        </LayoutContainer>
      </Router>
    );
  }
}

EnterpriseLayout.propTypes = {
  loading: PropTypes.bool.isRequired,
  children: PropTypes.any.isRequired,
  language: PropTypes.string.isRequired,
  onLanguageChange: PropTypes.func.isRequired,
  mixpanel: PropTypes.object.isRequired,
  signOut: PropTypes.func.isRequired,
  supportSingleLanguage: PropTypes.bool.isRequired,
  extendContentContainer: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
};

export default EnterpriseLayout;
