/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import Header from './Header';
import EnterpriseLayout from '../app/components/EnterpriseLayout';

const HeaderSelector = ({
  enableEnterpriseLayout,
  breadcrumbProps,
  supportSingleLanguage,
  ...props
}) => {
  if (enableEnterpriseLayout) {
    return <EnterpriseLayout {...props} />;
  }
  if (enableEnterpriseLayout === false) {
    return <Header {...props}>{props.children}</Header>;
  }
  return props.children;
};

HeaderSelector.propTypes = {
  user: PropTypes.object,
  getUser: PropTypes.func,
  children: PropTypes.node,
};

export default HeaderSelector;
