import { API, isFound, Urls, Cookie } from '@vezeeta/web-utils';

import Pricing from './Pricing';
import { Config } from '../../_Objects';

class PaymentCycles {
  constructor() {
    this.cached = false;
    this.pricings = [];
  }

  loadPricings = async productLineId => {
    await new Promise(resolve => {
      if (!this.isLoaded()) {
        const authToken = Cookie.get(Cookie.AUTH_TOKEN);
        const tempAuthToken = Cookie.get(Cookie.TEMP_AUTH_TOKEN);
        const getPricingsHeader = [
          {
            key: 'Culture',
            value: Config.getInstance().getCulture(),
          },
          {
            key: 'x-vzt-authentication',
            value: `${authToken || tempAuthToken}`,
          },
        ];

        const getPricings = new API();
        getPricings
          .get(`${Urls.productLines.getProductPricings}${productLineId}`, getPricingsHeader)
          .then(response => {
            this.cached = true;
            response.data.forEach(pricing => {
              this.addPricing()
                .setPriceTypeId(pricing.PriceTypeId)
                .setName(pricing.Name);
            });

            resolve();
          });
      } else {
        resolve();
      }
    });
  };

  isLoaded = () => this.cached;

  addPricing = () => {
    const newPricing = new Pricing();
    this.pricings.push(newPricing);
    return newPricing;
  };

  getPricings() {
    return this.pricings;
  }

  getPricingById(pricingId) {
    const result = this.pricings.filter(pricing => pricing.getPricingId() === pricingId);

    if (isFound(result)) {
      return result[0];
    }
  }
}

export default PaymentCycles;
