class Area {
  constructor() {
    this.areaId = undefined;
    this.cityId = undefined;
    this.nameEnglish = undefined;
    this.nameArabic = undefined;
    this.latitude = undefined;
    this.longitude = undefined;
    this.displayOrderEnglish = undefined;
    this.displayOrderArabic = undefined;
    this.isDeleted = undefined;
  }

  setAreaId = areaId => {
    this.areaId = areaId;
    return this;
  };

  getAreaId() {
    return this.areaId;
  }

  setCityId = cityId => {
    this.cityId = cityId;
    return this;
  };

  getCityId() {
    return this.cityId;
  }

  setLatitude = latitude => {
    this.latitude = latitude;
    return this;
  };

  getLatitude() {
    return this.latitude;
  }

  setLongitude = longitude => {
    this.longitude = longitude;
    return this;
  };

  getLongitude() {
    return this.longitude;
  }

  setNameEnglish = nameEnglish => {
    this.nameEnglish = nameEnglish;
    return this;
  };

  getNameEnglish() {
    return this.nameEnglish;
  }

  setNameArabic = nameArabic => {
    this.nameArabic = nameArabic;
    return this;
  };

  getNameArabic() {
    return this.nameArabic;
  }

  setDisplayOrderEnglish = displayOrderEnglish => {
    this.displayOrderEnglish = displayOrderEnglish;
    return this;
  };

  getDisplayOrderEnglish() {
    return this.displayOrderEnglish;
  }

  setDisplayOrderArabic = displayOrderArabic => {
    this.displayOrderArabic = displayOrderArabic;
    return this;
  };

  getDisplayOrderArabic() {
    return this.displayOrderArabic;
  }

  setIsDeleted = isDeleted => {
    this.isDeleted = isDeleted;
    return this;
  };

  getIsDeleted() {
    return this.isDeleted;
  }
}

export default Area;
