import { Cookie } from '@vezeeta/web-utils';
import { Culture } from 'app/helpers';

let instance = null;
const NEXT = 1;
const PREVIOUS = -1;

class Navigator {
  constructor() {
    this.urls = undefined;
    this.mainUrl = undefined;
    this.progressBar = undefined;
    this.loadingSpinner = undefined;
    this.history = undefined;
    this.currentIndex = 0;
    this.allowedIndex = 0;

    this.setPageTitle('Welcome');
  }

  static getInstance() {
    if (!instance) {
      instance = new Navigator();
    }

    return instance;
  }

  setUrls = urls => {
    this.urls = urls;
    return this;
  };

  getUrls() {
    return this.urls;
  }

  setMainUrl = mainUrl => {
    this.mainUrl = mainUrl;
    return this;
  };

  setProgressBar = progressBar => {
    this.progressBar = progressBar;
    return this;
  };

  setLoadingSpinner = loadingSpinner => {
    this.loadingSpinner = loadingSpinner;
    return this;
  };

  setHistory = history => {
    this.history = history;
    return this;
  };

  /**
   * Gets the index of the current pathname and extract the index from the
   * this.urls array
   */
  getCurrentIndex = () => {
    let { pathname } = window.location;
    pathname = pathname.replace(this.mainUrl, '');

    if (Culture.isCultureSpecified()) {
      pathname = `${Culture.getPathnameWithoutCulture(pathname)}`;
    }
    return this.getIndex(pathname);
  };

  getAllowedIndex = () => {
    if (Cookie.get(Cookie.SIGN_UP_STEP)) {
      this.allowedIndex = Cookie.get(Cookie.SIGN_UP_STEP);
    }

    return this.allowedIndex;
  };

  /**
   * Get the index of a url
   * @param {string} pathname
   */
  getIndex = pathname => {
    const numberOfSlashes = (pathname.match(/\//g) || []).length;
    const indexSecondSlash = pathname.split('/', 2).join('/').length;
    let newPathname = pathname;

    if (indexSecondSlash !== -1 && numberOfSlashes >= 2) {
      newPathname = pathname.substr(0, indexSecondSlash);
    }

    this.currentIndex = this.urls.findIndex(url => url.includes(newPathname));
    return this.currentIndex;
  };

  isFirstRoute = () => this.getCurrentIndex() === 0;

  /**
   * Switching to next step
   */
  nextStep = (subUrl, saveCookie = true) => {
    let newSubUrl = subUrl;

    if (newSubUrl === undefined) {
      newSubUrl = '';
    }

    if (saveCookie) {
      this.saveCookie(this.getCurrentIndex());
    }
    this.navigateStep(NEXT, newSubUrl);
  };

  /**
   * Switching to previous step
   */
  previousStep = () => {
    this.navigateStep(PREVIOUS);
  };

  /**
   * Navigate to a custom url
   * @param {string} url
   * @param {string} subUrl
   */
  customStep = (url, subUrl) => {
    const newIndex = this.getIndex(url);
    if (newIndex < this.urls.length) {
      this.changeLocation(this.urls[newIndex], subUrl);
      if (this.progressBar) {
        this.progressBar.updateProgress(newIndex + 1);
      }
    }
  };

  /**
   * Changing the step
   * @param {number} direction
   */
  navigateStep = (direction, subUrl) => {
    const newIndex = this.getCurrentIndex() + direction;
    if (newIndex < this.urls.length) {
      this.changeLocation(this.urls[newIndex], subUrl);
      if (this.progressBar) {
        this.progressBar.updateProgress(newIndex + 1);
      }
    }
  };

  /**
   * Change the pathname in location object to the new url
   * @param {string} newLocation
   */
  changeLocation = (newLocation, subUrl = '') => {
    let modifiedUrl = subUrl;
    if (modifiedUrl) {
      modifiedUrl = `/${subUrl}`;
    }
    this.history.push(`${this.mainUrl}${newLocation}${modifiedUrl}`);
  };

  /**
   * Show loading spinner
   */
  showLoadingSpinner() {
    if (this.loadingSpinner) {
      this.loadingSpinner.style.display = 'flex';
    }
  }

  /**
   * Hide loading spinner
   */
  hideLoadingSpinner() {
    if (this.loadingSpinner) {
      this.loadingSpinner.style.display = 'none';
    }
  }

  isAllowed() {
    this.getAllowedIndex();
    this.getCurrentIndex();

    return this.currentIndex <= this.allowedIndex;
  }

  redirect() {
    if (Cookie.get(Cookie.SIGN_UP_STEP)) {
      this.allowedIndex = Cookie.get(Cookie.SIGN_UP_STEP);
    }
    this.customStep(this.urls[this.allowedIndex]);
  }

  getLatestStep() {
    this.allowedIndex = Cookie.get(Cookie.SIGN_UP_STEP);
    return this.urls[this.allowedIndex];
  }

  saveCookie = index => {
    Cookie.set(Cookie.SIGN_UP_STEP, index + 1);
  };

  setPageTitle = title => {
    document.title = `${title} | Vezeeta`;
  };
}

export default Navigator;
