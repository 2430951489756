/* global process */
let instance = null;

class CreditCard {
  constructor() {
    this.cardNumber = process.env.REACT_APP_CREDIT_CARD_NUMBER;
    this.fullName = process.env.REACT_APP_CARDHOLDER_NAME;
    this.month = process.env.REACT_APP_MONTH;
    this.year = process.env.REACT_APP_YEAR;
    this.cvv = process.env.REACT_APP_CVV;
  }

  static getInstance() {
    if (!instance) {
      instance = new CreditCard();
    }

    return instance;
  }

  setCardNumber = cardNumber => {
    this.cardNumber = cardNumber;
    return this;
  };

  getCardNumber() {
    return this.cardNumber;
  }

  setFullName = fullName => {
    this.fullName = fullName;
    return this;
  };

  getFullName() {
    return this.fullName;
  }

  setMonth = month => {
    this.month = month;
    return this;
  };

  getMonth() {
    return this.month;
  }

  setYear = year => {
    this.year = year;
    return this;
  };

  getYear() {
    return this.year;
  }

  setCcv = ccv => {
    this.cvv = ccv;
    return this;
  };

  getCcv() {
    return this.cvv;
  }
}

export default CreditCard;
