class SpecialtyGroup {
  constructor() {
    this.specialtyGroupId = undefined;
    this.nameEnglish = undefined;
    this.nameArabic = undefined;
    this.displayOrderEnglish = undefined;
    this.displayOrderArabic = undefined;
  }

  setSpecialtyGroupId = specialtyGroupId => {
    this.specialtyGroupId = specialtyGroupId;
    return this;
  };

  getSpecialtyGroupId() {
    return this.specialtyGroupId;
  }

  setNameEnglish = nameEnglish => {
    this.nameEnglish = nameEnglish;
    return this;
  };

  getNameEnglish() {
    return this.nameEnglish;
  }

  setNameArabic = nameArabic => {
    this.nameArabic = nameArabic;
    return this;
  };

  getNameArabic() {
    return this.nameArabic;
  }

  setDisplayOrderEnglish = displayOrderEnglish => {
    this.displayOrderEnglish = displayOrderEnglish;
    return this;
  };

  getDisplayOrderEnglish() {
    return this.displayOrderEnglish;
  }

  setDisplayOrderArabic = displayOrderArabic => {
    this.displayOrderArabic = displayOrderArabic;
    return this;
  };

  getDisplayOrderArabic() {
    return this.displayOrderArabic;
  }
}

export default SpecialtyGroup;
