let instance = null;

class Config {
  constructor() {
    this.countryId = 1;
    this.country = 'Egypt';
    this.culture = 'en-EG';
    this.countryIsoCode = 'EG';
    this.localCurrency = 'EGP';
    this.localCurrencyId = 1;
    this.billingCurrency = 'EGP';
    this.billingCurrencyId = 1;
    this.countryCodeId = 306;
  }

  static getInstance() {
    if (!instance) {
      instance = new Config();
    }

    return instance;
  }

  setCountryId = countryId => {
    this.countryId = countryId;
    return this;
  };

  getCountryId() {
    return this.countryId;
  }

  setCountryIsoCode = countryIsoCode => {
    this.countryIsoCode = countryIsoCode;
    return this;
  };

  getCountryIsoCode = () => this.countryIsoCode;

  setCountryName = countryName => {
    this.countryName = countryName;
    return this;
  };

  getCountryName() {
    return this.countryName;
  }

  setCulture = culture => {
    this.culture = culture;
    return this;
  };

  getCulture() {
    return this.culture;
  }

  setLocalCurrency = localCurrency => {
    this.localCurrency = localCurrency;
    return this;
  };

  getLocalCurrency() {
    return this.localCurrency;
  }

  setLocalCurrencyId = localCurrencyId => {
    this.localCurrencyId = localCurrencyId;
    return this;
  };

  getLocalCurrencyId() {
    return this.localCurrencyId;
  }

  setBillingCurrency = billingCurrency => {
    this.billingCurrency = billingCurrency;
    return this;
  };

  getBillingCurrency() {
    return this.billingCurrency;
  }

  setBillingCurrencyId = billingCurrencyId => {
    this.billingCurrencyId = billingCurrencyId;
    return this;
  };

  getBillingCurrencyId() {
    return this.billingCurrencyId;
  }

  setCountryCodeId = countryCodeId => {
    this.countryCodeId = countryCodeId;
    return this;
  };

  getCountryCodeId() {
    return this.countryCodeId;
  }
}

export default Config;
