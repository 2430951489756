import { API, isFound, Urls, Cookie } from '@vezeeta/web-utils';

import Area from './Area';

let instance = null;

class Areas {
  constructor() {
    this.cached = false;
    this.areas = [];
  }

  static getInstance() {
    if (!instance) {
      instance = new Areas();
    }

    return instance;
  }

  /**
   * Load areas array
   * @param {array} areas
   */
  loadAreas = async () => {
    await new Promise(resolve => {
      if (!this.isLoaded()) {
        const getAreas = new API();
        const authToken = Cookie.get(Cookie.AUTH_TOKEN);
        const header = [
          {
            key: 'x-vzt-authentication',
            value: `${authToken}`,
          },
        ];
        // eslint-disable-next-line no-underscore-dangle
        getAreas.get(Urls.getAreas, header).then(response => {
          response.data.forEach(area => {
            const englishLanguageItemModel = area.LanguageItemModels.find(
              model => model.LanguageId === 1,
            );
            const arabicLanguageItemModel = area.LanguageItemModels.find(
              model => model.LanguageId === 2,
            );
            if (area.AreaId !== 0) {
              this.addArea()
                .setAreaId(area.Id)
                .setCityId(area.CityId)
                .setNameEnglish(englishLanguageItemModel.Name)
                .setNameArabic(arabicLanguageItemModel.Name)
                .setDisplayOrderEnglish(englishLanguageItemModel.DisplayOrder)
                .setDisplayOrderArabic(arabicLanguageItemModel.DisplayOrder)
                .setIsDeleted(area.IsDeleted);
            }
          });

          this.cached = true;
          resolve();
        });
      } else {
        resolve();
      }
    });
  };

  isLoaded = () => this.cached;

  /**
   * Create a new Area() object, push to this.areas and return the new object
   */
  addArea = () => {
    const newArea = new Area();
    this.areas.push(newArea);
    return newArea;
  };

  getAreas() {
    return this.areas;
  }

  /**
   * Filter areas by their city id
   * @param {number} cityId
   */
  filterByCityId = cityId => this.areas.filter(area => area.getCityId() === cityId);

  getAreaById = areaId => {
    const result = this.areas.filter(area => area.getAreaId() === areaId);

    if (isFound(result)) {
      return result[0];
    }
  };
}

export default Areas;
