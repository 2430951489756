/* global process */

import { STATUS, API, Cookie, Urls } from '@vezeeta/web-utils';

import { User, Clinic, Cart, ProductLines, PaymentCycles } from '../../_Objects';

let instance = null;

class CareProduct {
  constructor() {
    this.productLines = ProductLines.getInstance();
    this.paymentCycles = new PaymentCycles();

    this.productLineId = 2;
    this.numberOfDoctors = 1;
    this.paymentFrequency = 1;
    this.pricePerDoctor = 0;
    this.allowPaymentPlans = [];
    this.cached = false;
    this.registered = false;

    this.customizeCareHeader = [
      {
        key: 'AccountKey',
        value: User.getInstance().getAccountKey(),
      },
      {
        key: 'EntityKey',
        value: Clinic.getInstance().getClinicKey(),
      },
    ];
  }

  static getInstance() {
    if (!instance) {
      instance = new CareProduct();
    }

    return instance;
  }

  async loadCareDetails(numberOfDoctors, paymentFrequency, pricePerDoctor) {
    await new Promise(resolve => {
      this.paymentCycles.loadPaymentCycles(this.productLineId).then(() => {
        if (!this.isCached()) {
          if (numberOfDoctors && paymentFrequency && pricePerDoctor) {
            this.setNumberOfDoctors(numberOfDoctors).setPaymentFrequency(paymentFrequency);

            this.pricePerDoctor = pricePerDoctor;
            this.registered = true;
            this.cached = true;
            resolve();
          } else if (Cookie.get(Cookie.CARE_PRODUCT)) {
            const getCareDetails = new API();
            getCareDetails
              .get(Urls.productLines.getCustomizeCare, this.customizeCareHeader)
              .then(response => {
                this.setNumberOfDoctors(response.data.NumberOfDoctors).setPaymentFrequency(
                  response.data.PaymentFrequency,
                );

                this.pricePerDoctor = response.data.FeesModel[0].Value;
                this.registered = true;
                this.cached = true;
                resolve();
              });
          } else {
            this.getPrice();
            resolve();
          }
        } else {
          this.getPrice();
          resolve();
        }
      });
    });
  }

  isCached = () => this.cached;

  saveCookie = () => {
    Cookie.set(Cookie.CARE_PRODUCT, true);
  };

  getProductLineId() {
    return this.productLineId;
  }

  setNumberOfDoctors = numberOfDoctors => {
    this.numberOfDoctors = numberOfDoctors;
    return this;
  };

  getNumberOfDoctors() {
    return this.numberOfDoctors;
  }

  setPaymentFrequency = paymentFrequency => {
    this.paymentFrequency = paymentFrequency;
    this.getPrice();
    this.setAllowedPaymentPlans();

    return this;
  };

  getPaymentFrequency() {
    return this.paymentFrequency;
  }

  getPricePerDoctor() {
    return this.pricePerDoctor;
  }

  getPrice = () => {
    this.pricePerDoctor = this.productLines
      .getProductLineById(this.productLineId)
      .getProductLineDetailsByPaymentCycleId(this.paymentFrequency)
      .getProductLineAttribute()
      .getFeesModel()
      .getValue();
  };

  setAllowedPaymentPlans() {
    this.allowPaymentPlans = this.productLines
      .getProductLineById(this.productLineId)
      .getProductLineDetailsByPaymentCycleId(this.paymentFrequency)
      .getProductLineAttribute()
      .getFeesModel()
      .getAllowedPaymentPlans();
  }

  getAllowedPaymentPlans() {
    return this.allowPaymentPlans;
  }

  register = () => {
    this.registered = true;
    this.productLines.getProductLineById(this.productLineId).register();
  };

  isAlreadyRegistered() {
    return this.registered;
  }

  getPaymentCycles() {
    return this.paymentCycles.getPaymentCycles();
  }

  addToCart = () => {
    this.cart = Cart.getInstance();
    let { product } = this.cart.isProductInCart(this.getProductLineId());
    const { isInCart } = this.cart.isProductInCart(this.getProductLineId());

    if (isInCart) {
      product.clearItems();
    } else {
      product = this.cart.addProduct();
      product
        .setProductInstance(this)
        .setProductName('Vezeeta Care')
        .setProductUrl('care')
        .setProductId(this.getProductLineId());
    }

    product
      .addItem()
      .setNumberOfUnits(this.getNumberOfDoctors())
      .setUnit(this.getNumberOfDoctors() > 1 ? 'Doctors' : 'Doctor')
      .setTotalPrice(this.pricePerDoctor * this.numberOfDoctors)
      .setPaymentCycle(
        this.paymentCycles.getPaymentCycleById(this.getPaymentFrequency()).getNameEnglish(),
      );

    this.cart.setAllowedPaymentPlans(this.allowPaymentPlans, true);
  };

  remove = async () => {
    await new Promise(resolve => {
      const care = {
        NumberOfDoctors: 0,
        PaymentFrequency: this.paymentFrequency,
      };

      const deleteCareProduct = new API();
      deleteCareProduct
        .post(Urls.productLines.customizeCare, care, this.customizeCareHeader)
        .then(response => {
          if (response.status === STATUS.SUCCESS || response.status === STATUS.CREATED) {
            this.numberOfDoctors = 1;
            this.productLines.getProductLineById(this.productLineId).unRegister();
            Cookie.remove(Cookie.CARE_PRODUCT, {
              domain: process.env.REACT_APP_TOKEN_DOMAIN,
            });
            resolve();
          }
        });
    });
  };
}

export default CareProduct;
