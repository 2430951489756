import React from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';

const useRoute = ({ ...props }) => {
  const { path, render, onChange, moduleName } = props;
  const onRenderPropsChanges = routeProps => {
    onChange(moduleName, routeProps.location.pathname);
    return render(routeProps);
  };
  return <Route path={path} render={onRenderPropsChanges} />;
};

useRoute.propTypes = {
  moduleName: PropTypes.string,
  onChange: PropTypes.func,
  path: PropTypes.string,
  render: PropTypes.func,
};

export default useRoute;
