import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { loadClinics } from '@vezeeta/enterprise-store/lib/actions/clinics';

class ClinicsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isUserLoaded: false,
    };
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    const { language, clinics, user, countries, isUserLoaded } = nextProps;
    if (
      clinics.length === 0 &&
      user &&
      countries.length > 0 &&
      isUserLoaded !== prevState.isUserLoaded &&
      !nextProps.clinicsLoading
    ) {
      nextProps.loadClinics(language);
    }
  }

  render() {
    return <Fragment>{this.props.children}</Fragment>;
  }
}

ClinicsContainer.propTypes = {
  clinicsLoading: PropTypes.bool.isRequired,
  countries: PropTypes.array.isRequired,
  clinics: PropTypes.array.isRequired,
  user: PropTypes.object,
  loadClinics: PropTypes.func.isRequired,
  children: PropTypes.object.isRequired,
  isUserLoaded: PropTypes.bool.isRequired,
  language: PropTypes.string.isRequired,
};

ClinicsContainer.defaultProps = {
  user: undefined,
};

const mapStateToProps = state => ({
  clinicsLoading: state.clinics.loading,
  countries: state.country.Countries,
  clinics: state.clinics.Clinics,
  user: state.user.user,
  isUserLoaded: state.user.loaded,
});

const mapDispatchToProps = dispatch => ({
  loadClinics: language => dispatch(loadClinics(language)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ClinicsContainer);
