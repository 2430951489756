import { API, Urls, Cookie } from '@vezeeta/web-utils';

let instance = null;

class CitiesAreaHv {
  constructor() {
    this.cached = false;
    this.cities = [];
    this.AreaNeighbours = [];
  }

  static getInstance() {
    if (!instance) {
      instance = new CitiesAreaHv();
    }

    return instance;
  }

  /**
   * Load areas array
   * @param {array} cities
   */
  loadAreas = async countryId => {
    await new Promise(resolve => {
      if (!this.isLoaded()) {
        const getAreas = new API();
        const authToken = Cookie.get(Cookie.AUTH_TOKEN);
        const header = [
          {
            key: 'x-vzt-authentication',
            value: `${authToken}`,
          },
        ];
        // eslint-disable-next-line no-underscore-dangle
        getAreas
          .get(`${Urls.getCityAreaHv}?countryid=${countryId}&BookingType=3`, header)
          .then(response => {
            this.cities = response.data;
            this.cached = true;
            resolve();
          });
      } else {
        resolve();
      }
    });
  };

  getCityByAreaId = AreaId => this.cities.find(city => city.Areas.find(area => area.Id === AreaId));

  getAreaNeighboursByAreaId = AreaId => {
    const selectedCity = this.cities.find(city => city.Areas.find(area => area.Id === AreaId));
    const areaNeighboursHV = selectedCity.Areas.find(area => area.Id === AreaId).AreaNeighbours;
    return areaNeighboursHV;
  };

  isLoaded = () => this.cached;

  /**
   * Filter areas by their city id
   * @param {number} cityId
   */
  filterByCityId = cityId => this.areas.filter(area => area.getCityId() === cityId);
}

export default CitiesAreaHv;
