import { isFound } from '@vezeeta/web-utils';

import ProductLineAttribute from './ProductLineAttribute';

class ProductLineDetails {
  constructor() {
    this.productLineDetailsId = undefined;
    this.paymentCycleId = undefined;
    this.specialtyGroupId = undefined;
    this.priceTypeId = undefined;
    this.descriptionEnglish = undefined;
    this.descriptionArabic = undefined;
    this.productLineAttributes = [];
  }

  setProductLineDetailsId = productLineDetailsId => {
    this.productLineDetailsId = productLineDetailsId;
    return this;
  };

  getProductLineDetailsId() {
    return this.productLineDetailsId;
  }

  setPaymentCycleId = paymentCycleId => {
    this.paymentCycleId = paymentCycleId;
    return this;
  };

  getPaymentCycleId() {
    return this.paymentCycleId;
  }

  setSpecialtyGroupId = specialtyGroupId => {
    this.specialtyGroupId = specialtyGroupId;
    return this;
  };

  getSpecialtyGroupId() {
    return this.specialtyGroupId;
  }

  setPriceTypeId = priceTypeId => {
    this.priceTypeId = priceTypeId;
    return this;
  };

  getPriceTypeId() {
    return this.priceTypeId;
  }

  setDescriptionEnglish = descriptionEnglish => {
    this.descriptionEnglish = descriptionEnglish;
    return this;
  };

  getDescriptionEnglish() {
    return this.descriptionEnglish;
  }

  setDescriptionArabic = descriptionArabic => {
    this.descriptionArabic = descriptionArabic;
    return this;
  };

  getDescriptionArabic() {
    return this.descriptionArabic;
  }

  /**
   * Create a new product line attribute and returns it
   */
  addProductLineAttribute = () => {
    const newProductLineAttribute = new ProductLineAttribute();
    this.productLineAttributes.push(newProductLineAttribute);
    return newProductLineAttribute;
  };

  getProductLineAttributesById(productLineAttributeId) {
    const result = this.productLineAttributes.filter(
      productLineAttributes =>
        productLineAttributes.getProductLineAttributeId() === productLineAttributeId,
    );

    if (isFound(result)) {
      return result[0];
    }
  }

  getProductLineAttributes() {
    return this.productLineAttributes;
  }

  getProductLineAttribute() {
    return this.productLineAttributes[0];
  }
}

export default ProductLineDetails;
