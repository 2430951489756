import FeesModel from './FeesModel';

class ProductLineAttribute {
  constructor() {
    this.productLineAttributesId = undefined;
    this.paymentAttributeId = undefined;
    this.countryId = undefined;
    this.feesModels = new FeesModel();
  }

  setProductLineAttributeId = productLineAttributesId => {
    this.productLineAttributesId = productLineAttributesId;
    return this;
  };

  getProductLineAttributeId() {
    return this.productLineAttributesId;
  }

  setPaymentAttributeId = paymentAttributeId => {
    this.paymentAttributeId = paymentAttributeId;
    return this;
  };

  getPaymentAttribute() {
    return this.paymentAttributeId;
  }

  setCountryId = countryId => {
    this.countryId = countryId;
    return this;
  };

  getCountryId() {
    return this.countryId;
  }

  getFeesModel() {
    return this.feesModels;
  }
}

export default ProductLineAttribute;
