import styled from 'styled-components';

export const LayoutContainer = styled.div`
  grid-template-areas: 'b a' 'b c';
  overflow: hidden;
  width: 100%;
  direction: ${props => (props.language === 'en' ? 'ltr' : 'rtl')};
  ${props => props.theme.media.desktop`
    height: 100vh;
    display: grid;
    display: -ms-grid;
    grid-template-columns: 0px auto;
    ms-grid-column: 0px auto;
    grid-template-rows: 60px auto;
    ms-grid-row: 60px auto;
  `};
`;

export const ContentContainer = styled.div`
  grid-area: c;
  max-height: calc(100vh - 0px);
  overflow-y: auto;
  ${props => props.theme.media.phone`
    min-height: Calc(100vh - 160px);
  `}
  ${props => props.theme.media.tablet`
    min-height: Calc(100vh - 160px);
  `}
  ${props => (props.extendContentContainer ? props.extendContentContainer : '')};
`;
