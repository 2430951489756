import { API, Urls } from '@vezeeta/web-utils';

import PaymentType from './PaymentType';
import { Config, Cart, Headers } from '../../_Objects';

let instance = null;

class PaymentTypes {
  constructor() {
    this.cached = false;
    this.paymentTypes = [];

    this.config = Config.getInstance();
    this.cart = Cart.getInstance();
  }

  static getInstance() {
    if (!instance) {
      instance = new PaymentTypes();
    }

    return instance;
  }

  loadPaymentTypes = async () => {
    await new Promise(resolve => {
      if (!this.isLoaded()) {
        const getPaymentTypes = new API();

        getPaymentTypes
          .get(
            `${Urls.payment.getPaymentTypes}countryid=${this.config.getCountryId()}&languageId=1`,
            Headers.getPaymentHeaders(),
          )
          .then(response => {
            response.data.forEach(paymentType => {
              const newPaymentType = this.addPaymentType();
              newPaymentType
                .setName(paymentType.Name)
                .setDescription(paymentType.Description)
                .setPaymentTypeKey(paymentType.PaymentTypeKey);

              paymentType.PaymentMethods.forEach(paymentMethod => {
                const newPaymentMethod = newPaymentType.addPaymentMethod();
                newPaymentMethod
                  .setPaymentMethodKey(paymentMethod.PaymentMethodKey)
                  .setName(paymentMethod.Name)
                  .setLogo(paymentMethod.IconUrl)
                  .setDescription(paymentMethod.Description);
              });
            });

            this.cached = true;
            resolve();
          });
      } else {
        resolve();
      }
    });
  };

  isLoaded = () => this.cached;

  addPaymentType = () => {
    const newPaymentType = new PaymentType();
    this.paymentTypes.push(newPaymentType);
    return newPaymentType;
  };

  getPaymentTypes() {
    const allowedPaymentTypes = this.paymentTypes.filter(paymentType =>
      this.cart.isPaymentPlanAllowed(paymentType.getPaymentTypeKey()),
    );

    return allowedPaymentTypes;
  }
}

export default PaymentTypes;
