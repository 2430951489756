class Headers {
  static getPaymentHeaders = () => [
    {
      key: 'x-vzt-authorization',
      value:
        'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhcHBpZCI6InNlcnZpY2VzIiwiZXhwIjoxNTI2NDAxMzc2LjAsIm5uYyI6IjZiZjk3NTk3ZDliNTczYmUifQ.qlUP48qVZc-0ocJl8ZM1un2b6-c34VhUs2Pa89EKkME',
    },
    {
      key: 'x-vzt-component',
      value: 'AMWeb',
    },
  ];
}

export default Headers;
