import React from 'react';
import ReactDOM from 'react-dom';
import { HttpsRedirection, ErrorBoundary, theme } from '@vezeeta/web-components';
import { ThemeProvider } from 'styled-components';
import { Provider } from 'react-redux';
import store from '@vezeeta/enterprise-store/lib';

import App from 'views/App';
import * as serviceWorker from './serviceWorker';

const imageUrl = `${process.env.REACT_APP_CDN_URL}/assets/maintenance.svg`;

const refreshPage = () => {
  window.location.reload();
};

if (window && window.caches) {
  console.log('...');
  caches.keys().then(names => {
    names.forEach(name => {
      caches.delete(name);
    });
  });
}

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <ErrorBoundary imageUrl={imageUrl} callbackAction={refreshPage}>
      <HttpsRedirection disableHttps={process.env.REACT_APP_FORCE_HTTPS === 'false'}>
        <Provider store={store}>
          <App />
        </Provider>
      </HttpsRedirection>
    </ErrorBoundary>
  </ThemeProvider>,
  document.getElementById('root'),
);

serviceWorker.unregister();
