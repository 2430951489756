class PaymentMethod {
  constructor() {
    this.paymentMethodKey = undefined;
    this.name = undefined;
    this.logo = undefined;
    this.description = undefined;
  }

  setPaymentMethodKey = paymentMethodKey => {
    this.paymentMethodKey = paymentMethodKey;
    return this;
  };

  getPaymentMethodKey() {
    return this.paymentMethodKey;
  }

  setName = name => {
    this.name = name;
    return this;
  };

  getName() {
    return this.name;
  }

  setLogo = logo => {
    this.logo = logo;
    return this;
  };

  getLogo() {
    return this.logo;
  }

  setDescription = description => {
    this.description = description;
    return this;
  };

  getDescription() {
    return this.description;
  }
}

export default PaymentMethod;
